<div class="display-flex flexColumn">
  <div class="width-60-pc">
    <div class="display-flex experience-title">
      <h3 nz-typography>Education</h3>
    </div>

    <div class="display-flex experience-title">
      <nz-avatar [nzSize]="64" nzSrc="/assets/isel.png" class="experience-item-ml"></nz-avatar>
      <h4 nz-typography>Instituto Superior de Engenharia de Lisboa</h4>
    </div>

    <nz-timeline>
      <nz-timeline-item>
        <div class="display-flex">
          <h6 nz-typography>Telecommunications, electronics and computer engineering licenciate degree</h6>
        </div>
        <app-timeline [start]="date1" [end]="date2"></app-timeline>

        <nz-timeline>
          <nz-timeline-item>
            <b nz-typography>Low voltage DC-DC boost Converter</b>
            <app-timeline [start]="date3" [end]="date4"></app-timeline>
            <p nz-typography>
              <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
              Study, design, development, and testing of a circuit capable of boosting the voltage of a photovoltaic
              cell from 0.3V/1V to 2.5V/4V. Using only discrete components.
            </p>
          </nz-timeline-item>

          <nz-timeline-item>
            <b nz-typography>Digital anemometer</b>
            <app-timeline [start]="date5" [end]="date6"></app-timeline>
            <p nz-typography>
              <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
              Development of an anemometer based on the Arduino platform for hardware interaction and windows GUI (WPF)
              for data analyses and configuration.
            </p>
          </nz-timeline-item>
        </nz-timeline>

      </nz-timeline-item>
    </nz-timeline>
  </div>
  <div class="pl-50-px">
    <div class="display-flex experience-title">
      <h3 nz-typography>Online Courses</h3>
    </div>
    <nz-timeline>
      <nz-timeline-item>
        <div class="display-flex academic-title">
          <nz-avatar [nzSize]="32" nzSrc="/assets/udemy.png" class="experience-item-ml"></nz-avatar>
          <b nz-typography>Master your Brain: Neuroscience for personal development</b>
        </div>
        <div class="display-flex academic-title">
          <i nz-icon nzType="info-circle" nzTheme="twotone" class="experience-item-ml"></i>
          Udemy
        </div>
        <app-timeline [start]="date7"></app-timeline>
      </nz-timeline-item>

      <nz-timeline-item>
        <div class="display-flex academic-title">
          <nz-avatar [nzSize]="32" nzSrc="/assets/udemy.png" class="experience-item-ml"></nz-avatar>
          <b nz-typography>Design Patterns in C# and .NET</b>
        </div>
        <div class="display-flex academic-title">
          <i nz-icon nzType="info-circle" nzTheme="twotone" class="experience-item-ml"></i>
          Udemy
        </div>
        <app-timeline [start]="date8"></app-timeline>
      </nz-timeline-item>

      <nz-timeline-item>
        <div class="display-flex academic-title">
          <nz-avatar [nzSize]="32" nzSrc="/assets/udemy.png" class="experience-item-ml"></nz-avatar>
          <b nz-typography>Scrum Certification Prep +Scrum Master+ Agile Scrum Training</b>
        </div>
        <div class="display-flex academic-title">
          <i nz-icon nzType="info-circle" nzTheme="twotone" class="experience-item-ml"></i>
          Udemy
        </div>
        <app-timeline [start]="date9"></app-timeline>
      </nz-timeline-item>

      <nz-timeline-item>
        <div class="display-flex academic-title">
          <nz-avatar [nzSize]="32" nzSrc="/assets/scrumstudy.png" class="experience-item-ml"></nz-avatar>
          <b nz-typography>Scrum Fundamentals</b>
        </div>
        <div class="display-flex academic-title">
          <i nz-icon nzType="info-circle" nzTheme="twotone" class="experience-item-ml"></i>
          SCRUMstudy
        </div>
        <app-timeline [start]="date10"></app-timeline>
      </nz-timeline-item>

    </nz-timeline>
  </div>
</div>