<div class="display-flex experience-title">
  <nz-avatar [nzSize]="64" nzSrc="/assets/ankix.png"></nz-avatar>
  <h4 nz-typography>Ankix</h4>
</div>

<nz-timeline>
  <nz-timeline-item>
    <div class="display-flex">
      <nz-avatar [nzSize]="24" nzSrc="/assets/NEC.png" class="experience-item-ml"></nz-avatar>
      <h6 nz-typography>Backend developer &#64; NEC</h6>
    </div>
    <app-timeline [start]="date1" [end]="date2"></app-timeline>
    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Development of a configurable finite state machine in a message driven architecture, with significant emphasis on
      concurrent programming.
      <br>The state machine was configured by multiple JSON files that would contain one or more complete workflows,
      each one for a specific purpose.
      <br>A workflow was compromised of several activities and could be triggered by an event message, while an activity
      represented a .net class that could be anything from a simple sum to a complex webservice call. In the context of
      a workflow an activity could just be used as a black box with a set of inputs and outputs.
      <br>Implemented Aea’s aviation protocol on a Kaba boarding gate from the ground up as well as most .net state
      machine activities.
      <br>Each activity contained a Validate (pre-execution, would validate business logic), Execute (actual activity
      function), and Rollback (would revert whatever action was done in Execute) methods, unit tests were also
      implemented for most of them. Some core workflows contained integration tests.
      <br>Played a key in troubleshooting and implementing some core solution features such as Request-Response based
      messages, and the integration tests solution.
      <br>Was responsible for writing documentation for each activity as well as most core workflows.
    </p>

  </nz-timeline-item>
</nz-timeline>

<div class="techstack-div">
  <b>TechStack</b>
  <div>
    <nz-tag nzColor="magenta">.NET Core 3.1</nz-tag>
    <nz-tag nzColor="red">xUnit</nz-tag>
    <nz-tag nzColor="volcano">RabbitMQ</nz-tag>
    <nz-tag nzColor="orange">MassTransit</nz-tag>
    <nz-tag nzColor="gold">Powershell</nz-tag>
    <nz-tag nzColor="lime">Docker</nz-tag>
    <nz-tag nzColor="green">SonarQube</nz-tag>
    <nz-tag nzColor="cyan">Jenkins</nz-tag>
  </div>
</div>
