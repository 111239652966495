<div class="display-flex experience-title">
  <nz-avatar [nzSize]="64" nzSrc="/assets/alter.png"></nz-avatar>
  <h4 nz-typography>Alter Solutions</h4>
</div>

<nz-timeline>
  <nz-timeline-item>
    <div class="display-flex">
      <nz-avatar [nzSize]="24" nzSrc="/assets/vortal.png" class="experience-item-ml"></nz-avatar>
      <h6 nz-typography>Fullstack developer &#64; Vortal</h6>
    </div>
    <app-timeline [start]="date1" [end]="date2"></app-timeline>
    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Within all projects on Vortal I was responsible for task planning, developing complete requirements from UI down
      to DB with unit testing and authorization rules, and maintaining the existing solution with bug fixing.
      <br>All projects followed the SCRUM framework guidelines.
    </p>

    <nz-timeline>
      <nz-timeline-item>
        <b nz-typography>Supplier Truster</b>
        <app-timeline [start]="date1" [end]="date2"></app-timeline>
        <p nz-typography>
          <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
          Worked as a full stack developer on new developments for an existing application for e-tendering for a major
          Italian client. Developed complete Angular webApplications that were then integrated with the already existing
          platform, multiple complex free-text with additional field-based search with pagination and ordering. SQL
          query performance optimization, and multiple integrations with external SOAP webServices. Was responsible for
          defining part of the DB model. Played a key role in delivering the project within tight and difficult
          deadlines.
        </p>
      </nz-timeline-item>

      <nz-timeline-item>
        <b nz-typography>eInvoice</b>
        <app-timeline [start]="date3" [end]="date4"></app-timeline>
        <p nz-typography>
          <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
          Worked as a full stack developer on a project started from scratch, used to generate and maintain electronic
          invoice documents. Played a role in planning and in defining the project architecture, including DB model. Was
          responsible for integrating with external SOAP webServices. Implemented a cookie-based authentication system
          that integrated with external webServices.
        </p>
      </nz-timeline-item>

      <nz-timeline-item>
        <b nz-typography>ESPD</b>
        <app-timeline [start]="date5" [end]="date6"></app-timeline>
        <p nz-typography>
          <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
          Worked mostly as a front-end developer on an existing application used to generate standardized XML and PDF
          files for European e-sourcing. Developed complex reactive forms based UI elements, their corresponding backend
          XML mapping and PDF field generation.
        </p>
      </nz-timeline-item>
    </nz-timeline>
  </nz-timeline-item>
</nz-timeline>

<div class="techstack-div">
  <b>TechStack</b>
  <div>
    <nz-tag nzColor="magenta">ASP.netFramework 4.8</nz-tag>
    <nz-tag nzColor="red">.NET Core 3.1</nz-tag>
    <nz-tag nzColor="volcano">Microsoft SQL server 2017</nz-tag>
    <nz-tag nzColor="orange">Angular 9</nz-tag>
    <nz-tag nzColor="gold">Typescript</nz-tag>
    <nz-tag nzColor="lime">CSS</nz-tag>
    <nz-tag nzColor="green">HTML</nz-tag>
    <nz-tag nzColor="cyan">xUnit</nz-tag>
    <nz-tag nzColor="blue">SCRUM</nz-tag>
    <nz-tag nzColor="geekblue">Azure DevOps</nz-tag>
  </div>
</div>