<div class="display-flex">
  <i nz-icon nzType="camera" nzTheme="twotone" class="experience-item-ml"></i>
  <a href="https://github.com/BernardoCastelo/PhotoManager" target="_blank">
    <h6 nz-typography>PhotoManager</h6>
  </a>
</div>
<div class="display-flex">
  <div style="width: 99%;">
    <app-timeline [start]="date1"></app-timeline>
    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Web application to view photos stored locally on the same machine as the host.
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      ASP.netCore 5.0 Web Api; React 17 based Web application.
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Infinite Scroll.
      <br><i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Metadata sorting and filtering.
      <br><i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Full resolution Metadata viewer.
      <br><i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Full filepath search.
    </p>
    <div>
      <b>TechStack</b>
      <div>
        <nz-tag nzColor="magenta">.NET 5</nz-tag>
        <nz-tag nzColor="red">React 17</nz-tag>
        <nz-tag nzColor="volcano">SQL Server 2019</nz-tag>
        <nz-tag nzColor="orange">JavaScript</nz-tag>
        <nz-tag nzColor="orange">LINQ</nz-tag>
      </div>
    </div>
    <nz-divider></nz-divider>
    <img style="max-width: 40%; margin-right: 30px;" nz-image nzSrc="/assets/photomanager_fullres.png"
      alt=" " />
    <img style="max-width: 40%;" nz-image nzSrc="/assets/photomanager_main.png" alt=" " />
  </div>

  <div>
    <img style="max-width: 90%;" nz-image nzSrc="/assets/photomanager_side_drawer.png" alt=" " />
  </div>
</div>