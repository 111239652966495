<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar" nzCollapsible nzWidth="256px" nzBreakpoint="md" [nzCollapsedWidth]="0">
    <div class="sidebar-logo">
      <a href=" " target="_blank">
        <img nz-image nzSrc="/assets/me.jpg" alt=" " />
        <h1>Portfolio</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline">
      <li nz-menu-item nzMatchRouter>
        <i nz-icon nzType="user" nzTheme="outline" class="menu-item-mr"></i>
        <a routerLink="{{environment.homeRoute}}">About me</a>
      </li>
      <li nz-menu-item nzMatchRouter>
        <i nz-icon nzType="read" nzTheme="outline" class="menu-item-mr"></i>
        <a routerLink="{{environment.academicRoute}}">Academic</a>
      </li>
      <li nz-menu-item nzMatchRouter>
        <i nz-icon nzType="solution" nzTheme="outline" class="menu-item-mr"></i>
        <a routerLink="{{environment.experienceRoute}}">Experience</a>
      </li>
      <li nz-submenu nzTitle="Projects" nzIcon="project">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="{{environment.nixieRoute}}">Nixie Tube</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="{{environment.photoManagerRoute}}">PhotoManager</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a href="https://github.com/BernardoCastelo/Portfolio">Portfolio</a>
          </li>
        </ul>
      </li>
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-content>
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <nz-footer>
      <div class="display-flex">
        Bernardo Castelo, 2024
        <div class="flexColumn" style="margin-left: auto;">
          <div style="margin-right: 1rem;">
            Contact me:
          </div>
          <div style="margin-top: -2px">
            <a href="https://github.com/BernardoCastelo" target="_blank" class="link-icons"><i nz-icon nzType="github" nzTheme="fill"></i></a>
            <a href="https://www.linkedin.com/in/bcastelo/" target="_blank" class="link-icons"><i nz-icon nzType="linkedin" nzTheme="fill"></i></a>
            <a href="mailto: bernardo.castelo@gmail.com" target="_blank" class="link-icons"><i nz-icon nzType="mail" nzTheme="fill"></i></a>
            <a href="https://500px.com/p/soupcan?view=photos" target="_blank" class="link-icons"><i nz-icon nzType="camera" nzTheme="fill"></i></a>
          </div>
        </div>

      </div>
    </nz-footer>
  </nz-layout>
</nz-layout>