<div class="display-flex">
  <i nz-icon nzType="camera" nzTheme="twotone" class="experience-item-ml"></i>
  <a href="https://github.com/BernardoCastelo/Nixie_tube_clock" target="_blank">
    <h6 nz-typography>Cold cathode display clock</h6>
  </a>
</div>
<div class="display-flex">
  <div style="width: 99%;">
    <app-timeline [start]="date1"></app-timeline>

    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Arduino based cold cathode display clock (nixie tube).
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      IN12 displays.
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Firebeetle ESP-32.
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      NTE74141 BCD−to−Decimal Decoder.
      <br> <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      74HC4094N 8-bit Shift Register.
    </p>
    <div>
    </div>
  </div>
</div>

<nz-divider></nz-divider>
<img style="max-width: 40%; margin-right: 30px;" nz-image nzSrc="/assets/nixie.jpg" alt=" " />
<img style="max-width: 50%; margin-right: 30px;" nz-image nzSrc="/assets/nixie_board.png" alt=" " />