<div class="display-flex" style="width: 100%;">
  <div style="width: 100%; margin-top: 5vh;">
    <h1 nz-typography>Bernardo Castelo</h1>
    <img style="float: right; margin-top: -4rem; margin-right: 1rem;" nz-image height="150vh" nzSrc="/assets/me.jpg" alt=" " />
    <p nz-typography>
      Hello!
      <br>I am a fullstack developer with 6 years of experience with Microsoft back-end technologies, and with strong knowledge in Angular.
      <br> I am motivated by difficult challenges that force me to learn or re-learn something new, and through self
      improvement.
    </p>
    <p nz-typography>
      I am passionate about everything technological, love sports, music, and
        <a href="https://500px.com/p/soupcan" target="_blank">photography<Link/></a>.
      <br>
    </p>
  </div>
</div>

<div class="display-flex flexColumn" style="width: 100%; margin-top: 10vh">
  <div class="width-50-pc">
    <b>TechStack</b>
    <div class="mb-cards">
      <nz-tag nzColor="volcano">.NET 8</nz-tag>
      <nz-tag nzColor="volcano">.NET Core 3.1</nz-tag>
      <nz-tag nzColor="volcano">ASP.netFramework 4.8</nz-tag>
      <nz-tag nzColor="volcano">Microsoft SQL server 2017</nz-tag>
      <nz-tag nzColor="volcano">Microsoft SQL server 2019</nz-tag>
      <nz-tag nzColor="volcano">xUnit</nz-tag>
      <nz-tag nzColor="volcano">Altitude</nz-tag>
      <nz-tag nzColor="volcano">VBA</nz-tag>
      <nz-tag nzColor="volcano">C</nz-tag>
      <nz-tag nzColor="volcano">LINQ</nz-tag>
    </div>
    <div class="mb-cards">
      <nz-tag nzColor="cyan">Angular 7..17</nz-tag>
      <nz-tag nzColor="cyan">React 17</nz-tag>
      <nz-tag nzColor="cyan">TypeScript</nz-tag>
      <nz-tag nzColor="cyan">JavaScript</nz-tag>
      <nz-tag nzColor="cyan">CSS</nz-tag>
      <nz-tag nzColor="cyan">HTML</nz-tag>
    </div>
    <div class="mb-cards">
      <nz-tag nzColor="lime">Android</nz-tag>
    </div>
    <div class="mb-cards">
      <nz-tag nzColor="gold">RabbitMQ</nz-tag>
      <nz-tag nzColor="gold">MassTransit</nz-tag>
    </div>
    <div class="mb-cards">
      <nz-tag nzColor="geekblue">SonarQube</nz-tag>
      <nz-tag nzColor="geekblue">Azure DevOps</nz-tag>
      <nz-tag nzColor="geekblue">Powershell</nz-tag>
      <nz-tag nzColor="geekblue">Docker</nz-tag>
      <nz-tag nzColor="geekblue">Jenkins</nz-tag>
      <nz-tag nzColor="geekblue">GitLab</nz-tag>
      <nz-tag nzColor="geekblue">Jira</nz-tag>
      <nz-tag nzColor="geekblue">VSTS</nz-tag>
    </div>
    <div class="mb-cards">
      <nz-tag nzColor="magenta">SCRUM</nz-tag>
    </div>
  </div>
  <div class="width-50-pc">
    <div>
      <b>Soft skills</b>
      <div class="mb-cards">
        <nz-tag nzColor="gold">Microsoft Office</nz-tag>
        <nz-tag nzColor="volcano">Motivator</nz-tag>
        <nz-tag nzColor="cyan">Eye for detail</nz-tag>
        <nz-tag nzColor="lime">Team player</nz-tag>
        <nz-tag nzColor="geekblue">Thinking outside the box</nz-tag>
        <nz-tag nzColor="green">Attentiveness</nz-tag>
        <nz-tag nzColor="blue">Dedication</nz-tag>
        <nz-tag nzColor="red">Self-monitoring</nz-tag>
        <nz-tag nzColor="magenta">Self-directed</nz-tag>
      </div>
      <b>Languages</b>
      <div class="mb-cards display-flex flexColumn">
        <div class="display-flex experience-item-ml">
          <p nz-typography class="experience-item-ml">Portuguese: </p>
          <nz-tag nzColor="lime" style="height: min-content">Native</nz-tag>
        </div>
        <div class="display-flex experience-item-ml">
          <p nz-typography class="experience-item-ml">English: </p>
          <nz-tag nzColor="cyan" style="height: min-content">Fluent (Written)</nz-tag>
          <nz-tag nzColor="cyan" style="height: min-content">Fluent (Spoken)</nz-tag>
        </div>
      </div>
    </div>
  </div>
</div>
