<div>
  <app-equus></app-equus>
  <nz-divider nzDashed nzText="November 2021"></nz-divider>

  <app-ankix></app-ankix>
  <nz-divider nzDashed nzText="December 2020"></nz-divider>

  <app-alter></app-alter>
  <nz-divider nzDashed nzText="December 2019"></nz-divider>

  <app-bee></app-bee>
  <nz-divider nzDashed nzText="May 2018"></nz-divider>
</div>