<div class="display-flex experience-title">
  <nz-avatar [nzSize]="64" nzSrc="/assets/bee.png"></nz-avatar>
  <h4 nz-typography>Bee Engineering</h4>
</div>

<nz-timeline class="mt-20px">
  <nz-timeline-item>
    <div class="display-flex">
      <nz-avatar [nzSize]="24" nzSrc="/assets/santander.png" class="experience-item-ml"></nz-avatar>
      <h6 nz-typography>Backend Developer &#64; Santander</h6>
    </div>
    <app-timeline [start]="date1" [end]="date2"></app-timeline>

    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Migration of Santander’s call centre technology based on Altitude software from version 7 to 8. Basic
      troubleshooting of Altitude’s software regarding the automatic version conversion.
      <br>Adaptations to various .net web-services based on MVC pattern.
      <br>Due to limitations of the Altitude software regarding large data processing (por example data returned from a
      SQL query) various libraries were developed in C#, where most relied on LINQ to execute any data processing
      commands.
      <br>Many DB operation on SQL server such as stored procedures, linked servers and migration of data from other
      databases on different servers.
      <br>Complex SQL queries had to be converted to LINQ due to a limitation in Altitude software’s new version.
      <br>Source control on both legacy and new versions with Dev/Pre/Pro environments.
      <br>Configuration of a Altitude dev environment including telephony and email gateways, campaign configuration and
      agent configuration for inbound and outbound campaigns.
      <br>Many out of the box problem solving regarding telephony problems and altitude software due to a almost
      complete lack of documentation and debugging tools.
      <br>Integration with a small team of highly skilled and more experienced people.
    </p>
  </nz-timeline-item>
  <nz-timeline-item>
    <div class="display-flex">
      <nz-avatar [nzSize]="24" nzSrc="/assets/bee-logo.png" class="experience-item-ml"></nz-avatar>
      <h6 nz-typography>Trainee &#64; Bee Engineering</h6>
    </div>
    <app-timeline [start]="date3" [end]="date4"></app-timeline>
    <p nz-typography>
      <i nz-icon nzType="right-circle" nzTheme="twotone" class="experience-item-text-ml"></i>
      Native Android application to aid shift-working people in time management by providing a schedule map integrating
      multiple schedules from multiple companies and allowing the possibility to exchange shifts with another co-worker.
      Web based back-office for employee management, data visualization, and machine learning aided suggestions for
      future shift attributions to ensure most employees receive their desired schedule.
      <br>I was in this project as one of the members of the academy as a trainee along side other trainee colleagues.
      <br>We developed a native Android app a MongoDB database and a .net MVC webservice to connect the database to the
      Android app. I ended up being responsible for most of the web api development, including defining the MVC
      architecture, as well as a significant part of the backend of the Android app, I was also key in defining the core
      database structure.
      <br>Throughout the project we implemented a SCRUM working methodology with daily meetings and bi-weekly
      deliveries.
    </p>
  </nz-timeline-item>
</nz-timeline>

<div class="techstack-div">
  <b>TechStack</b>
  <div>
    <nz-tag nzColor="magenta">Altitude</nz-tag>
    <nz-tag nzColor="red">VBA</nz-tag>
    <nz-tag nzColor="volcano">C</nz-tag>
    <nz-tag nzColor="orange">LINQ</nz-tag>
    <nz-tag nzColor="gold">SQL Server</nz-tag>
    <nz-tag nzColor="lime">VSTS</nz-tag>
    <nz-tag nzColor="green">Android</nz-tag>
    <nz-tag nzColor="cyan">GitLab</nz-tag>
    <nz-tag nzColor="blue">Jira</nz-tag>
    <nz-tag nzColor="geekblue">.net Framework</nz-tag>
  </div>
</div>